// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// function ExternalRedirect() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     window.location.href =
//       "https://web-app-153945772792.northamerica-northeast2.run.app/";
//   }, [navigate]);

//   return null; // Return null as this component only handles redirection
// }

// export default ExternalRedirect;

import { useEffect } from "react";

function ExternalRedirect() {
  useEffect(() => {
    // You can add any other logic here if needed
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        src="https://web-app-153945772792.northamerica-northeast2.run.app/"
        style={{ width: "100%", height: "100%", border: "none" }}
        title="External App"
      />
    </div>
  );
}

export default ExternalRedirect;
